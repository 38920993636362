import { render, staticRenderFns } from "./MarkdownViewer.vue?vue&type=template&id=482e15da&scoped=true&"
import script from "./MarkdownViewer.vue?vue&type=script&lang=js&"
export * from "./MarkdownViewer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "482e15da",
  null
  
)

export default component.exports