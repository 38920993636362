<template>
  <v-card max-height="400" class="overflow-auto">
    <v-card-text>
    <VueShowdown
        class="pa-2"
      :markdown="value"
      flavor="github"
      :options="{ emoji: true }"
    />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "MarkdownViewer",
  props: {
    value: { type: String, default: "" }
  }
}
</script>

<style scoped>

</style>
